import { TextDecoration } from './interface';

export const globalDecoration: TextDecoration = {
  links: {
    CASTLE: 'http://castle.cse.ust.hk',
    HKUST: 'https://hkust.edu.hk',
    'Purdue University': 'https://www.purdue.edu/',
    'Lili Wei': 'https://liliweise.github.io/',
    'Yepang Liu': 'https://yepangliu.github.io/',
    'Shing-Chi Cheung': 'https://www.cse.ust.hk/~scc/',
    'Ming Wen': 'https://justinwm.github.io/',
    'Yongqiang Tian': 'http://home.cse.ust.hk/~ytianas/',
    'Jiajun Gong': 'https://jiajungong.github.io/',
    'Chengnian Sun': 'https://chengniansun.bitbucket.io/public/index.html',
    'Charles Zhang': 'https://cse.hkust.edu.hk/~charlesz/',
    'Tao Wang': 'https://www.cs.sfu.ca/~taowang/',
    'Michael R. Lyu': 'https://www.cse.cuhk.edu.hk/lyu/home',
    'Shuqing Li': 'https://shuqing-li.github.io/',
    'Shiqing Ma': 'https://people.cs.rutgers.edu/~sm2283/',
    'Yu Jiang': 'https://sites.google.com/site/jiangyu198964?pli=1',
    'Zhuo Zhang': 'https://zzhang.xyz/',
    'Qingkai Shi': 'https://qingkaishi.github.io/',
    'Xiangyu Zhang': 'https://www.cs.purdue.edu/homes/xyzhang/',
    MegaETH: 'https://megaeth.systems/',
    'Chengpeng Wang': 'https://chengpeng-wang.github.io',
    'Guannan Wei': 'https://continuation.passing.style/',
    'Danning Xie': 'https://dnxie.github.io/',
    'Xiangzhe Xu': 'https://xz-x.github.io/',
    ASTRI: 'https://www.astri.org/',
    MEDGA: 'https://medga.org/',
    Foundry: 'https://github.com/foundry-rs/foundry',
    Slither: 'https://github.com/crytic/slither',
    'Jialun Cao': 'https://bella-academic.netlify.app',
    'Mengya Zhang': 'https://miazmy1221.github.io/',
    'Xiaokuan Zhang': 'https://mainarke.github.io/',
    'Zhiqiang Lin': 'https://cse.osu.edu/people/lin.3021',
  },
  highlights: ['Wuqi Zhang'],
};
